





















import Vue from "vue";
import { get_color_by_name } from "@/utils/colors";

export default Vue.extend({
  name: "EditDetailsBtn",
  methods: {
    transform_edit_icon(icon: HTMLElement): HTMLElement {
      const color = get_color_by_name("primary");
      for (let node of icon.children) node.setAttribute("stroke", color);
      return icon;
    }
  },
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  }
});
