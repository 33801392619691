
































































































import Vue from "vue";
import JobTitle from "@/components/recruiter/jobs/JobTitle.vue";
import EditDetailsBtn from "@/components/recruiter/jobs/individual/EditDetailsBtn.vue";
import { parseStrToInt } from "@/utils/global";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import ViewJobLoading from "@/components/recruiter/jobs/ViewJobLoading.vue";
import {
  FETCH_SKILLS_GROUP,
  GET_JOB_BY_ID
} from "@/store/modules/common/constants";
import { JobsWithSkillsGroup } from "@/interfaces/data_objects/jobs";
import ExtraDetails from "@/components/shared/view_job_details/ExtraDetails.vue";
import JobDescriptionCard from "@/components/shared/view_job_details/JobDescriptionCard.vue";
import SkillsWithGroups from "@/components/shared/SkillsWithGroups.vue";
import { SkillGroupResponse } from "@/interfaces/responses/fetch_skill_group";
import { PostJobPayload } from "@/store/modules/recruiter/interfaces";
import {
  EDITED_STANDARDISED_JOB,
  HOLD_STANDARDISED_JOB,
  POST_JOB,
  PUSH_TOP_JOB_WITH_CANDIDATES,
  UPDATE_TOP_MATCHING_CANDIDATES
} from "@/store/modules/recruiter/constants";
import router from "@/router";
import GoBackHeader from "@/components/shared/GoBackHeader.vue";
// import { ZappyhirePostjobPayload } from "@/interfaces/recruiter/jobs/post_job/zappyhire_post_job";
import { GET_USER_DETAILS } from "@/store/modules/auth/constants";
// import { SkillList } from "@/store/modules/common/interfaces";

export default Vue.extend({
  name: "ViewStandardisedJob",
  beforeRouteEnter(to, from, next) {
    const param_id = to.params.id;
    if (!param_id || !parseStrToInt(param_id)) next(false);
    else next();
  },
  beforeRouteUpdate(to, from, next) {
    const param_id = to.params.id;
    if (!param_id || !parseStrToInt(param_id)) next(false);
    else next();
  },
  components: {
    GoBackHeader,
    SkillsWithGroups,
    JobDescriptionCard,
    ExtraDetails,
    ViewJobLoading,
    EditDetailsBtn,
    JobTitle
  },
  data() {
    return {
      loading: true,
      job: null as null | JobsWithSkillsGroup,
      post_job_loading: false
    };
  },
  computed: {
    ...mapGetters("recruiter", {
      get_hold_standardised_job: HOLD_STANDARDISED_JOB,
      get_edited_standardised_job: EDITED_STANDARDISED_JOB
    }),
    ...mapGetters("auth", {
      user_details: GET_USER_DETAILS
    })
  },
  async mounted() {
    this.loading = true;
    const jid = parseStrToInt(this.$route.params.id);
    if (!jid) {
      this.root_error("Invalid Job");
      await this.$router.push("/recruiter/jobs/post-job");
      return;
    }
    if (this.get_hold_standardised_job) {
      this.job = this.get_hold_standardised_job;
      this.loading = false;
      return;
    }
    // Fetching job
    const response = await this.get_job_by_id(jid);
    if (!response) {
      await this.$router.push("/recruiter/jobs/post-job");
      return;
    }
    // Fetching skills group
    let skills: string | string[] = response.skills_list;
    if (response.skills_list && typeof response.skills_list === "string") {
      skills = JSON.parse(response.skills_list);
      if (typeof skills === "string") skills = eval(skills);
    }
    response.skills_list = await this.fetch_skill_group(skills);
    this.job = response;
    this.hold_standardised_job(this.job);
    this.loading = false;
  },
  methods: {
    ...mapMutations({
      root_error: ROOT_ERROR,
      root_notification: ROOT_NOTIFICATION
    }),
    ...mapActions("common", {
      get_job_by_id: GET_JOB_BY_ID,
      fetch_skill_group: FETCH_SKILLS_GROUP
    }),
    ...mapActions("recruiter", {
      post_job: POST_JOB
      // zappyhire_post_job: ZAPPYHIRE_JOB_POST
    }),
    ...mapMutations("recruiter", {
      push_top_job_with_candidates: PUSH_TOP_JOB_WITH_CANDIDATES,
      update_top_matching_candidates: UPDATE_TOP_MATCHING_CANDIDATES,
      hold_standardised_job: HOLD_STANDARDISED_JOB
    }),
    async edit_job() {
      if (this.job)
        await this.$router.push(
          `/recruiter/jobs/post-job/${this.job.jid}/edit`
        );
    },
    async post_new_job() {
      if (this.job) {
        this.post_job_loading = true;
        const form_data = new FormData();
        if (!this.get_edited_standardised_job) {
          const payload = {
            is_standardize: true,
            job_id: this.job.jid.toString(),
            edited: false
          };
          for (let [key, value] of Object.entries(payload))
            form_data.append(key, value as string);
        } else {
          const skills: SkillGroupResponse[] = this.job.skills_list;
          let skills_list = [];
          for (let skill of skills) skills_list.push(skill.skill);
          // Payload for post-job
          const payload: PostJobPayload = {
            job_title: this.job.job_title,
            job_description: this.job.job_description,
            job_industry: this.job.sector ?? "",
            job_type: this.job.employment_type ?? "",
            job_experience: this.job.experience ?? "",
            skills_list: JSON.stringify(skills_list),
            is_standardize: true,
            edited: true
          };
          for (let [key, value] of Object.entries(payload))
            form_data.append(key, value);
        }
        form_data.append("post_type", "text");
        // Post job
        const response = await this.post_job(form_data);
        // If the job posted successfully
        if (response) {
          this.root_notification(this.$t("success-messages.job-post-success"));
          // Fetching the job from DB
          await this.fetch_job_and_update_top_jobs(
            response.standardized_data.jid
          );
          // if (this.user_details.zappyhire_configuration?.user_id) {
          //   const skills: SkillList[] =
          //     this.job && Array.isArray(this.job.skills_list)
          //       ? this.job.skills_list
          //       : [];
          //   let final_skills = "";
          //   for (let skill of skills) {
          //     final_skills += skill.skill + ", ";
          //   }
          //   if (final_skills.endsWith(", "))
          //     final_skills = final_skills.slice(0, final_skills.length - 2);
          //   let experience = 1;
          //   if (this.job?.experience) {
          //     experience = parseInt(this.job.experience.split("")[0]);
          //   }
          //   const payload = {
          //     job_id: response.standardized_data.jid,
          //     job_description: response.standardized_data.job_description,
          //     title: response.standardized_data.job_title,
          //     location_split: {
          //       kochi: 1,
          //       tvm: 1
          //     },
          //     skills: final_skills,
          //     completion_date: moment().format("YYYY-MM-DD"),
          //     academic_eligibility: "G",
          //     min_experience: experience,
          //     max_experience: experience,
          //     preferred_current_salary: "5-10"
          //   } as ZappyhirePostjobPayload;
          //   await this.zappyhire_post_job(payload);
          // }
          this.hold_standardised_job(null);
        } else {
          this.root_error(this.$t("errors.job-post-failed"));
        }
      }
      this.post_job_loading = false;
    },
    /**
     * Function to fetch job from DB and updating top job data set
     */
    async fetch_job_and_update_top_jobs(job_id: number) {
      const job = await this.get_job_by_id(job_id);
      const obj = {
        ...job,
        matching_candidates: [],
        candidates_loading: true
      };
      // Update top_posted_jobs data set
      this.push_top_job_with_candidates(obj);
      await router.push("/recruiter/jobs"); // Navigate to job listing page
    }
  }
});
